<template>
  <div class="my">
    <div class="user">
      <van-image
        round
        width="3rem"
        height="3rem"
        src="https://img.yzcdn.cn/vant/cat.jpeg"
      />
      <div class="names">
        <p class="name">{{ info.username }}</p>
        <p class="status">
          账户状态:{{ info.is_active ? "已激活" : "未激活" }}
        </p>
      </div>
    </div>
    <van-cell-group>
      <van-cell title="姓名" :value="info.name" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="岗位" :value="info.position" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="联系方式" :value="info.phone" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="联系地址" :value="info.address" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="电子邮件" :value="info.email" />
    </van-cell-group>
    <van-cell-group class="organization">
      <van-cell
        is-link
        title="组织人员"
        :value="info.organization_name"
        to="/personnel"
      />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="角色" :value="info.role_name" />
    </van-cell-group>
    <!--    <van-cell-group>-->
    <!--      <van-cell title="微信消息推送">-->
    <!--        <template #default>-->
    <!--          <van-switch v-model="info.wx_push" size="24px" />-->
    <!--        </template>-->
    <!--      </van-cell>-->
    <!--    </van-cell-group>-->
    <!-- <van-button type="primary" block @click="auth" style="margin-top:3%" v-if="!info.wx_openid">绑定微信</van-button> -->
    <van-button
      type="primary"
      block
      style="background: #fff; border: none; margin-top: 3%; color: #333"
      @click="logout"
      >退出登录</van-button
    >
  </div>
</template>

<script>
import { me, obtainwx, unbindWx } from "@/api/index";
import { Dialog } from "vant";
export default {
  name: "my",
  data() {
    return {
      checked: true,
      info: {
        is_active: true,
      },
    };
  },
  created() {
    const code = this.GetUrlParame("code"); // 截取code，授权返回的code
    const types = this.GetUrlParame("state");
    if (types == "my") {
      obtainwx({ code: code }).then((response) => {
        me().then((response) => {
          this.info = response.data;
        });
      });
    } else {
      me().then((response) => {
        this.info = response.data;
      });
    }
  },
  activated() {
    const code = this.GetUrlParame("code"); // 截取code，授权返回的code
    const types = this.GetUrlParame("state");
    if (types == "my") {
      obtainwx({ code: code }).then((response) => {
        me().then((response) => {
          this.info = response.data;
        });
      });
    } else {
      me().then((response) => {
        this.info = response.data;
      });
    }
  },
  methods: {
    logout() {
      Dialog.confirm({
        title: "退出",
        message: "确认退出登录吗?",
      })
        .then(() => {
          // on confirm
          unbindWx().then(() => {
            localStorage.removeItem("token");
            this.$router.push({ path: "/login" });
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    auth() {
      let appid = "wxe45e400c8c7df754"; // 可以通过后台获取
      //没有code
      let authPageBaseUri =
        "https://open.weixin.qq.com/connect/oauth2/authorize";
      //这里的 redirect_uri 用的当前页面地址，记得用 encodeURIComponent 做一下编码，这儿不注意容易报错
      let authParams = "";
      console.log(this.$route.fullPath);
      authParams = `?appid=${appid}&redirect_uri=${encodeURIComponent(
        window.location.origin + "/#/content/my"
      )}&response_type=code&scope=snsapi_base&state=my#wechat_redirect`;
      window.location.href = authPageBaseUri + authParams;
    },
    // 截取code
    GetUrlParame(parameName) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == parameName) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.my {
  .organization {
    .van-cell__right-icon {
      margin-left: 4px;
      color: #1989fa;
    }
    .van-cell__value span {
      color: #1989fa;
    }
  }
  .user {
    display: flex;
    padding: 50px 5% 20px 5%;
    background: #fff;

    p {
      padding: 0;
      margin: 0;
    }
    .names {
      margin-left: 6%;
    }
    .name {
      border-bottom: 1px solid #ccc;
      padding-bottom: 3px;
      margin-bottom: 5px;
    }
    .status {
      font-size: 12px;
    }
  }
}
</style>
